<script setup lang="ts">
import AppLink from "@/Components/Shared/link/AppLink.vue";
import AppTitle from "@/Components/Shared/title/AppTitle.vue";
import { getFormattedDate } from "@/Utils/Helpers";
import AppText from "@/Components/Shared/text/AppText.vue";

type Props = {
    sets: object;
    showDate?: boolean;
    hideGame?: boolean;
};

const getMonth = (date: string) => {
    const formattedDate = getFormattedDate(date);
    return new Date(formattedDate).toLocaleString("nl-NL", { month: "short" });
};

const getDay = (date: string) => {
    const formattedDate = getFormattedDate(date);
    return new Date(formattedDate).getDate();
};

const { sets, showDate = false } = defineProps<Props>();
</script>

<template>
    <ul class="flex flex-col gap-1">
        <li v-for="set in sets">
            <AppLink
                :href="set.path"
                class="flex gap-3 py-2 px-3 items-start hover:no-underline hover:bg-slate-100 rounded"
            >
                <div
                    v-if="showDate"
                    class="inline-flex flex-col shrink-0 p-2 w-[60px] gap-1 items-center justify-center text-white bg-violet-900 rounded"
                >
                    <span class="text-lg font-bold leading-none">{{
                        getDay(set.released_at)
                    }}</span>
                    <span class="text-sm leading-none">{{
                        getMonth(set.released_at)
                    }}</span>
                </div>

                <div class="flex flex-col gap-0.5">
                    <AppTitle
                        as="h3"
                        size="xxsmall"
                        class="line-clamp-2"
                    >
                        {{ set.name }}
                    </AppTitle>

                    <AppText
                        v-if="!hideGame"
                        color="grayLight"
                        size="xsmall"
                    >
                        {{ set.game.title }}
                    </AppText>
                </div>
            </AppLink>
        </li>
    </ul>
</template>
